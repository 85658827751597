<template>
    <v-container>
        <AdBTWR v-if="!user.isAdFree"/>

        <h1 class="page-header font-weight-light">My Subscriptions</h1>

        <div v-if="user.authenticated">

            <div class="text-right mb-2">
                <v-btn
                    color="rfaccent2"
                    dark
                    x-large
                    :disabled="!user.stripeCustomer"
                    @click="createCustomerPortalSession"
                >
                    <v-icon class="mr-1">mdi-credit-card-outline</v-icon>
                    Manage Billing
                </v-btn>
            </div>

            <v-container class="px-0">
                <v-row justify="center">
                    <v-col cols="12">
                        <LoadingAnimation v-if="loading"/>

                        <v-container v-else class="px-0">
                            <v-row class="d-flex justify-lg-space-around">
                                <PremiumMembershipRequired :user="user" v-if="subscriptions.length === 0"/>

                                <v-col
                                    cols="12"
                                    md="6"
                                    v-for="subscription in subscriptions"
                                    :key="subscription.id"
                                >
                                    <v-card
                                        color="rfaccent2"
                                        class="mb-5 elevation-10"
                                        dark
                                    >
                                        <v-card-title class="display-1 font-weight-light justify-center">
                                            {{ subscription.product.name }} Plan
                                        </v-card-title>
                                        <v-card-subtitle class="white--text subtitle-1 text-center">
                                            Your subscription ends on
                                            <strong>{{ $moment.unix(subscription.current_period_end).format('MMMM D, YYYY') }}</strong>
                                            <template v-if="!subscription.cancel_at">
                                                and will automatically be renewed for
                                                <strong>
                                                    ${{ getSubscriptionAmount(subscription) }} 
                                                    per {{ getSubscriptionInterval(subscription) }}
                                                </strong>
                                            </template>
                                            <template v-else-if="subscription.cancel_at">
                                                and <strong>will not</strong> be renewed
                                            </template>
                                        </v-card-subtitle>
                                        <v-card-text class="white--text text-center">
                                            <span v-html="svgInclude"></span>

                                            <span class="creditcards mr-2">
                                                <svg class="icon" v-if="getCardBrand(subscription) === 'visa'">
                                                    <use xlink:href="#visa-simple-light"></use>
                                                </svg>
                                                <svg class="icon" v-else-if="getCardBrand(subscription) === 'american express'">
                                                    <use xlink:href="#amex-simple-light"></use>
                                                </svg>
                                                <svg class="icon" v-else-if="getCardBrand(subscription) === 'discover'">
                                                    <use xlink:href="#discover-simple-light"></use>
                                                </svg>
                                                <svg class="icon" v-else-if="getCardBrand(subscription) === 'mastercard'">
                                                    <use xlink:href="#mc-simple-light"></use>
                                                </svg>
                                                <span v-else-if="getCardBrand(subscription)">
                                                    {{ getCardBrand(subscription).toUpperCase() }}
                                                </span>
                                            </span>

                                            <template v-if="hasPaymentMethod(subscription)">
                                                <span v-for="z in 4" :key="'third-' + z" class="title">&middot;</span>&nbsp;
                                                {{ subscription.paymentMethod.card.last4 }}
                                                Expires {{ formatExpiryDate(subscription.paymentMethod.card) }}
                                            </template>
                                        </v-card-text>
                                        <v-card-actions class="justify-space-between">
                                            <v-container class="px-0">
                                                <v-row>
                                                    <v-col cols="12" lg="6" class="text-center py-0">
                                                        <v-btn
                                                            :href="getInvoiceUrl(subscription)"
                                                            target="_blank"
                                                            color="almostblack"
                                                            class="mt-2"
                                                            dark
                                                            :block="$vuetify.breakpoint.mdAndDown"
                                                        >
                                                            View Latest Invoice
                                                            <v-icon small class="ml-1">mdi-open-in-new</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col cols="12" lg="6" class="text-center py-0">
                                                        <v-btn
                                                            color="error"
                                                            class="mt-2"
                                                            @click="openConfirmCancelDialog(subscription.id)"
                                                            :block="$vuetify.breakpoint.mdAndDown"
                                                            v-if="!subscription.cancel_at"
                                                        >
                                                            <v-icon small class="mr-1">mdi-close</v-icon>
                                                            Cancel Subscription
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div v-else>
            <v-alert type="warning" class="mt-5" border="left" elevation="3" prominent>
                You must be logged in to manage your subscriptions.
            </v-alert>
        </div>

        <v-dialog v-model="dialogs.confirmCancel.state" width="500">
            <v-card>
                <v-card-title>Confirm Cancellation</v-card-title>
                <v-card-text class="text-center">
                    Are you sure you want to cancel your subscription? Your benefits will continue until the end of the
                    term.

                    <v-btn block color="rfaccent2" dark @click="closeConfirmCancelDialog" class="mt-5">No, keep my
                        subscription
                    </v-btn>
                    <v-btn block color="error" @click="cancelSubscription(dialogs.confirmCancel.subscriptionId)"
                           :loading="dialogs.confirmCancel.loading" class="mt-2">Yes, cancel my subscription
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar.state" :color="snackbar.type" :timeout="snackbar.timeout">
            <v-icon v-if="snackbar.type === 'success'" class="mr-1">mdi-check</v-icon>
            {{ snackbar.text }}
        </v-snackbar>
    </v-container>
</template>

<style>
    .creditcards .icon {
        /*display: inline-block;*/
        vertical-align: middle;
        /*margin-right: 1em;*/
        width: 45px;
        height: 30px;
    }
</style>

<script>
    import AdBTWR from '@/components/ads/BTWR.vue';
    import LoadingAnimation from '@/components/LoadingAnimation.vue';
    import PremiumMembershipRequired from '@/components/PremiumMembershipRequired.vue';
    import { useTitle } from '@vueuse/core';
    import axios from 'axios';
    import config from '@/config';

    export default {
        props: ['user'],

        components: {
            AdBTWR,
            LoadingAnimation,
            PremiumMembershipRequired
        },

        data: () => ({
            loading: false,
            amount: 1000,
            publishableKey: 'pk_test_ocl3RNwb0sYL69NuaAje1OSX',
            token: null,
            charge: null,
            checkingInterval: null,
            headers: [
                // {value: 'id', text: 'ID'},
                {value: 'product.name', text: 'Product'},
                {value: 'status', text: 'Status'},
                // {value: 'latest_invoice', text: 'Invoice'},
                // {value: 'created', text: 'Created Date'},
                {value: 'start_date', text: 'Start Date'},
                // {value: 'ended_at', text: 'Ended Date'},
                // {value: 'cancel_at', text: 'Cancel At'},
                {value: 'canceled_at', text: 'Canceled At'},
                // {value: 'billing_cycle_anchor', text: 'Billing Cycle Anchor'},
                {value: 'current_period_start', text: 'Period Start Date'},
                {value: 'current_period_end', text: 'Period End Date'},
                {value: 'plan.amount', text: 'Amount'},
                // {value: 'plan.interval', text: 'Interval'},
                // {value: 'plan.interval_count', text: 'Interval Count'},
                {value: 'invoice.hosted_invoice_url', text: 'Latest Invoice'},
                // {value: 'cancel_at_period_end', text: 'Auto Renew'},
                {value: 'actions', text: ''},
            ],
            subscriptions: [],
            stripe: {
                publishableKey: null,
                monthlyPrice: null,
                yearlyPrice: null
            },
            snackbar: {
                state: false,
                timeout: 10000,
                type: 'info',
                text: null
            },
            dialogs: {
                confirmCancel: {
                    state: false,
                    subscriptionId: null,
                    loading: false
                }
            },
            svgInclude: null
        }),

        methods: {
            loadSubscriptions() {
                let axiosHeaders = {};
                if (this.user && this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios.get(`${require('../../../assets/cc/svgdefs-2_copy.svg')}`)
                    .then(response => {
                        this.svgInclude = response.data;
                    });

                this.loading = true;
                axios
                    .get(`${config.API_LOCATION}/subscriptions`, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.subscriptions = response.data;
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },

            createCustomerPortalSession() {
                let axiosHeaders = {};
                if (this.user && this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                this.loadingRedirect = true;

                axios
                    .post(`${config.API_LOCATION}/subscription/customer-portal-session`, {}, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        if (response.data.redirectUrl) window.location = response.data.redirectUrl;
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .then(() => {
                        this.loadingRedirect = false;
                    });
            },

            openConfirmCancelDialog(subscriptionId) {
                this.dialogs.confirmCancel.subscriptionId = subscriptionId;
                this.dialogs.confirmCancel.state = true;
            },

            closeConfirmCancelDialog() {
                this.dialogs.confirmCancel.state = false;
                this.dialogs.confirmCancel.subscriptionId = null;
                this.dialogs.confirmCancel.loading = false;
            },

            cancelSubscription(subscriptionId) {
                let axiosHeaders = {};
                if (this.user && this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                this.dialogs.confirmCancel.loading = true;

                axios
                    .delete(`${config.API_LOCATION}/subscription/${subscriptionId}`, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        console.log(response.data);
                    })
                    .catch(err => {
                        console.error(err);
                    })
                    .then(() => {
                        this.closeConfirmCancelDialog();
                        this.snackbar.text = 'Your subscription has been canceled successfully.';
                        this.snackbar.type = 'success';
                        this.snackbar.state = true;
                        this.loadSubscriptions();
                    });
            },

            formatExpiryDate(card) {
                return this.$moment()
                    .month(card.exp_month - 1)
                    .date(1)
                    .hour(0)
                    .minute(0)
                    .second(0)
                    .millisecond(0)
                    .year(card.exp_year)
                    .format('MM/YY');
            },

            getSubscriptionAmount(subscription) {
                return ((subscription.items && 
                        subscription.items.data && 
                        subscription.items.data[0] && 
                        subscription.items.data[0].plan && 
                        subscription.items.data[0].plan.amount) 
                        ? subscription.items.data[0].plan.amount / 100 
                        : 0).toFixed(2);
            },

            getSubscriptionInterval(subscription) {
                return subscription.items?.data[0]?.plan?.interval || 'month';
            },

            getCardBrand(subscription) {
                return subscription.paymentMethod?.card?.brand?.toLowerCase() || '';
            },

            hasPaymentMethod(subscription) {
                return subscription.paymentMethod && subscription.paymentMethod.card;
            },

            getInvoiceUrl(subscription) {
                return subscription.invoice && subscription.invoice.hosted_invoice_url;
            },
        },

        mounted() {
            useTitle('My Subscriptions - myGMRS.com');

            this.loadSubscriptions();
        }
    }
</script>
